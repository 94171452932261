@use "../../globals/colors" as colors;

.child-category-list-container {
	.icon-button {
		align-items: center;
		border: 2px solid colors.$white;
		border-radius: 50px;
		display: flex;
		height: 48px;
		justify-content: center;
		width: 48px;
	}
}
