.tac-content {
	margin: 0;
	padding: 0;
	.tac-heading {
		display: flex;
		img {
			max-height: 95px;

			@media screen and (min-width: 900px) {
				max-height: 120px;
			}
		}
	}
	h1 {
		border-left: solid 2px #2b2a27;
		font-family: $body-font-condensed-bold;
		font-size: 28px;
		font-weight: 700;
		line-height: 32px;
		margin-bottom: 40px;
		margin-left: 16px;
		padding-left: 16px;
		text-transform: uppercase;
		width: 195px;

		@media screen and (min-width: 900px) {
			font-size: 36px;
			line-height: 40px;
			margin-left: 32px;
			padding-left: 32px;
			width: 256px;
		}
	}
	> section {
		display: flex;
		justify-content: center;
		> div {
			max-width: 674px;
			padding-left: 16px;
			padding-right: 16px;
			width: 100%;
		}
	}
}
.tac-description {
	background-color: #f7f7f6;
	padding-bottom: 40px;
	padding-top: 40px;
	p {
		line-height: 22px;
		margin: 0;
		&:first-of-type {
			font-family: $body-font-condensed-bold;
			font-size: 20px;
			font-weight: 700;
			line-height: 30px;
		}
	}
}
.tac-signup {
	background-color: map-get($lfi-palette, jet);
	color: map-get($lfi-palette, white);
	h2 {
		font-family: $body-font-family-medium;
		font-size: 28px;
		padding: 72px 0 16px;
		text-transform: uppercase;
	}
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}
	li {
		align-items: center;
		border-bottom: 1px solid #d39e04;
		display: flex;
		margin: 0;
		padding: 32px 0;
	}
	.event-location {
		font-size: 24px;
		font-weight: 700;
		line-height: 30px;
		margin-bottom: 8px;
	}
	.event-dates {
		line-height: 22px;
		text-transform: uppercase;
	}
	.event-info {
		flex-grow: 1;
		padding-right: 16px;
		> span {
			display: block;
		}
	}
	button {
		margin:0;
		min-width: 167px;
	}
	.calendly {
		height: 630px;
		width: 100%;
	}
}
.tac-questions {
	padding-bottom: 80px;
	padding-top: 56px;
	text-align: center;
	p {
		margin-bottom: 24px;
	}
}
